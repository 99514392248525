"use client";
import Section from "@/components/new-components/wrapper/Section";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

import H1 from "../headers/H1";
import P from "../headers/P";

const PersonalAiAdvisor = () => {
  const router = useRouter();
  return (
    <Section className="pt-12 ">
      <div className="lg:flex flex-row gap-5 px-4">
        <div className="flex flex-col gap-5 flex-[0.3]">
          <H1>
            Your Personal <br />
            <span className="text-[#22C55E]">AI Advisior</span>
          </H1>
          <P className="md:text-2xl">
            Experience the convenience of instant answers to all your
            health-related queries.
            <span className="text-white">
              {" "}
              Ask anything, anytime, and let our AI guide you with accurate and
              personalized information.
            </span>{" "}
            Your wellness journey just got smarter with Bonatra&apos;s AI.
          </P>
          {/* <Button className="px-8 w-28 hidden lg:flex"> Know more</Button> */}
          <div className="gap-5 mt-1 hidden lg:flex hover:cursor-pointer">
            <Image
              src="/bonatra/app-store.webp"
              height={150}
              width={150}
              alt="logo"
              onClick={() =>
                router.push(
                  "https://apps.apple.com/in/app/bonatra/id1600079061"
                )
              }
            />
            <Image
              src="/bonatra/play-store.webp"
              height={150}
              width={150}
              alt="logo"
              onClick={() =>
                router.push(
                  "https://play.google.com/store/apps/details?id=com.yoihealth"
                )
              }
            />
          </div>
        </div>
        <div className="flex-[0.7] p-0 mt-5 lg:mt-0">
          <Image
            src="/bonatra/personal-ai-advisor.webp"
            alt="img"
            height={850}
            width={850}
          />

          <div className="flex gap-3 justify-center mt-5 lg:hidden hover:cursor-pointer">
            <Image
              src="/bonatra/app-store.webp"
              height={150}
              width={150}
              alt="logo"
              onClick={() =>
                router.push(
                  "https://apps.apple.com/in/app/bonatra/id1600079061"
                )
              }
            />
            <Image
              src="/bonatra/play-store.webp"
              height={150}
              width={150}
              alt="logo"
              onClick={() =>
                router.push(
                  "https://play.google.com/store/apps/details?id=com.yoihealth"
                )
              }
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PersonalAiAdvisor;
