"use client";
import React from "react";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import Section from "@/components/new-components/wrapper/Section";
import CardWrapper from "@/app/components/CardWrapper";
import P from "@/app/components/headers/P";
import H1 from "@/app/components/headers/H1";
import { useRouter } from "next/navigation";

const UnlockYourTrueAgeSection = () => {
  const router = useRouter();
  return (
    <Section className="mt-12">
      <CardWrapper className="flex flex-col gap-5 lg:flex-row bg-transparent">
        <H1 className="lg:hidden ">Unlock your true age</H1>
        <CardWrapper className="md:flex md:justify-center lg:flex-[0.5] lg:justify-start bg-transparent">
          <H1 className=" absolute hidden lg:block text-5xl">
            Unlock your true age
          </H1>
          <Image
            src="/bonatra/unlock-true-age.webp"
            height={500}
            width={600}
            alt="img"
            className="lg:mt-14"
          />
        </CardWrapper>
        <div className="md:flex flex-col items-center lg:flex-[0.4] lg:items-start lg:mt-28">
          <P className="mt-5 md:text-center lg:text-left lg:text-3xl">
            Answer a few simple questions about your lifestyle to{" "}
            <span className="text-white">
              calculate your biological age and gain personalized insights to
              guide you towards a longer, healthier future
            </span>
            . Unlock the secrets to longevity and well-being at your fingertips
          </P>
          <Button
            className="px-8 mt-5 lg:mt-8 font-normal"
            onClick={() => router.push("/biologicalAgeCalculator")}
          >
            Try for Free
          </Button>
        </div>
      </CardWrapper>
    </Section>
  );
};

export default UnlockYourTrueAgeSection;
