"use client";
import Section from "@/components/new-components/wrapper/Section";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";
import H1 from "../headers/H1";
import P from "../headers/P";

const MoreMetrics = () => {
  const router = useRouter();
  return (
    <Section className="flex flex-col gap-5  py-10">
      <H1 className=" w-48 md:w-full">
        More Metrics <span className="text-[#22C55E]">More Insights</span>.
      </H1>
      <P>
        Experience seamless integration with your
        <span className="text-white"> existing wellness ecosystem.</span>
        Bonatra&apos;s app effortlessly syncs with
        <span className="text-white"> Google Fit and Apple Health,</span>
        ensuring you can enjoy the benefits of our Smart Ring insights without
        any additional hassle
      </P>
      <div className="flex gap-5 mt-5 ">
        <Image
          src="/bonatra/app-store.webp"
          height={150}
          width={150}
          alt="logo"
          onClick={() =>
            router.push("https://apps.apple.com/in/app/bonatra/id1600079061")
          }
          className=" hover:cursor-pointer"
        />
        <Image
          src="/bonatra/play-store.webp"
          height={150}
          width={150}
          alt="logo"
          onClick={() =>
            router.push(
              "https://play.google.com/store/apps/details?id=com.yoihealth"
            )
          }
          className=" hover:cursor-pointer"
        />
      </div>
      <div className=" flex justify-center items-center">
        <Image
          src="/bonatra/more-metrics-more-insights.webp"
          height={800}
          width={800}
          alt="img"
        />
      </div>
    </Section>
  );
};

export default MoreMetrics;
