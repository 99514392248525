"use client";
import { Card, CardContent } from "@/components/ui/card";
import { LinkedinIcon } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

type TfounderData = {
  name: string;
  imgSrc: string;
  position: string;
  profileLink: string;
};

const FounderCard = ({ data }: { data: TfounderData }) => {
  const router = useRouter();
  return (
    <Card className="border-none">
      <CardContent>
        <div className="flex justify-center ">
          <div className="w-20 h-20 rounded-full  bg-green-700 blur-3xl absolute"></div>
          <div className="w-32 h-32 rounded-full z-50 hover:cursor-pointer">
            <Image
              src={data.imgSrc}
              alt="img"
              height={300}
              width={300}
              className="z-50"
            />
          </div>
        </div>

        <h1 className="mt-3 text-xl">{data.name}</h1>
        <p className="text-[#22C55E] mb-3">{data.position}</p>

        <Image
          src="/bonatra/linkedin.webp"
          alt="linkedin icon"
          height={40}
          className="hover:cursor-pointer"
          width={40}
          onClick={() => router.push(data.profileLink)}
        />
      </CardContent>
    </Card>
  );
};

export default FounderCard;
