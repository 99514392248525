"use client";
import Section from "@/components/new-components/wrapper/Section";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";
import CardWrapper from "../CardWrapper";
import GradientSpan from "../headers/GradientSpan";
import H1 from "../headers/H1";

const AiBasedTechnoly = () => {
  const router = useRouter();
  return (
    <Section className="pt-10">
      <H1 className="mb-5">
        <span className="text-[#22C55E]">AI</span> Based Technology
      </H1>
      <div className=" flex flex-col gap-3  ">
        <div className="flex flex-col lg:flex-row gap-3  lg:h-[500px] items-center ">
          <CardWrapper className="relative  h-[500px] lg:flex-[0.4] w-[330px] md:w-full">
            <GradientSpan className="absolute text-xl z-10  lg:text-2xl">
              {" "}
              Bonatra Smart Ring X1
            </GradientSpan>
            <div>
              <Image
                src="/bonatra/gold-ring-hand.webp"
                alt="image of arm with gold ring"
                fill
                className="hidden md:block "
              />
              <Image
                src="/bonatra/gold-ring-hand.webp"
                alt="image of arm with gold ring"
                fill
                className="md:hidden z-0"
              />
            </div>
          </CardWrapper>
          <CardWrapper className="bg-black flex flex-col gap-5 items-center lg:items-start lg:flex-[0.6] lg:flex-row lg:h-[500px] w-[330px] md:w-full">
            <div className="flex flex-col items-center px-4 ">
              <p className="text-lg text-[#797979] font-semibold mb-3 md:hidden">
                Track sleep, movement, HRV, temperature,{" "}
                <span className="text-white">
                  SpO2 for optimal health with sleek and stylish design.
                </span>
              </p>
              <Image
                src="/bonatra/dashboardss.webp"
                height={200}
                width={200}
                alt="img"
                className="lg:hidden"
              />
              <Image
                src="/bonatra/dashboardss.webp"
                height={230}
                width={230}
                alt="img"
                className="hidden lg:block"
              />
            </div>
            <div className="flex  flex-col md:justify-start lg:items-start items-center gap-5  lg:w-96 mt-3 ">
              <p className="text-3xl font-semibold mb-3 hidden md:block text-[#797979]">
                Track{" "}
                <span className="text-white">
                  {" "}
                  sleep, movement, HRV, temperature, SpO2{" "}
                </span>
                for optimal health with sleek and stylish design.
              </p>
              <Button
                className="px-8 font-normal"
                onClick={() => router.push("/bonatra-ring-x1")}
              >
                Buy Now
              </Button>
              {/* <div className="flex flex-col md:flex-row gap-5 mt-3 hover:cursor-pointer">
                <Image
                  src="https://assets.bonatra.com/Website%20Assets/Appstore.webp"
                  height={100}
                  width={100}
                  alt="logo"
                  className="hover:cursor-pointer"
                  onClick={() =>
                    router.push(
                      "https://apps.apple.com/in/app/bonatra/id1600079061"
                    )
                  }
                />
                <Image
                  src="https://assets.bonatra.com/Website%20Assets/Playstore.webp"
                  height={100}
                  width={100}
                  alt="logo"
                  className="hover:cursor-pointer"
                  onClick={() =>
                    router.push(
                      "https://play.google.com/store/apps/details?id=com.yoihealth"
                    )
                  }
                />
              </div> */}
            </div>
          </CardWrapper>
        </div>
        <div className="flex flex-col lg:flex-row gap-3  lg:h-[500px] items-center">
          <CardWrapper className="relative  h-[500px] lg:flex-[0.4] flex justify-center w-[330px] md:w-full">
            <GradientSpan className="absolute left-0 px-4 text-xl lg:text-2xl">
              Continuous Glucose Monitor
              <br />
              (CGM)
            </GradientSpan>
            <Image
              src="/bonatra/priyanshi-cgm.webp"
              alt="image of arm with gold ring"
              height={250}
              width={250}
              className=""
            />
          </CardWrapper>
          <CardWrapper className=" flex flex-col gap-5 items-center lg:items-start lg:flex-[0.6] lg:flex-row lg:h-[500px] w-[330px] md:w-full">
            <div className="flex flex-col items-center px-4 ">
              <p className="text-lg font-semibold mb-3 md:hidden">
                A cutting-edge technology designed to revolutionize{" "}
                <span className="text-white">
                  diabetes management. CGM provides real-time
                </span>{" "}
                insights into your blood sugar levels.
              </p>
              <Image
                src="/bonatra/blood-glucose.webp"
                height={200}
                width={200}
                alt="img"
                className="lg:hidden"
              />
              <Image
                src="/bonatra/blood-glucose.webp"
                height={230}
                width={230}
                alt="img"
                className="hidden lg:block"
              />
            </div>
            <div className="flex  flex-col md:justify-start lg:items-start items-center gap-5  lg:w-96 mt-3 ">
              <p className="text-3xl font-semibold mb-3 hidden md:block text-[#797979]">
                A cutting-edge technology designed to revolutionize{" "}
                <span className="text-white text-3xl font-medium">
                  diabetes management. CGM provides real-time
                </span>{" "}
                insights into your blood sugar levels.
              </p>
              <Button
                className="px-8 font-normal text-black"
                onClick={() => router.push("/all-products")}
              >
                Buy Now
              </Button>
              {/* <div className="flex flex-col md:flex-row gap-5 mt-3 hover:cursor-pointer">
                <Image
                  src="https://assets.bonatra.com/Website%20Assets/Appstore.webp"
                  height={100}
                  width={100}
                  alt="logo"
                  onClick={() =>
                    router.push(
                      "https://apps.apple.com/in/app/bonatra/id1600079061"
                    )
                  }
                />
                <Image
                  src="https://assets.bonatra.com/Website%20Assets/Playstore.webp"
                  height={100}
                  width={100}
                  alt="logo"
                  onClick={() =>
                    router.push(
                      "https://play.google.com/store/apps/details?id=com.yoihealth"
                    )
                  }
                />
              </div> */}
            </div>
          </CardWrapper>
        </div>
      </div>
    </Section>
  );
};

export default AiBasedTechnoly;
// const heroImages = [
//   {
//     logo: testPiloImg,
//     link: "https://www.google.com/search?client=firefox-b-d&q=bpnatra#lrd=0x3bae15b96461a1ad:0x2f533c2161a49fa8,1",
//   },
//   {
//     logo: playstoreImg,
//     link: "https://play.google.com/store/apps/details?id=com.yoihealth",
//   },
//   {
//     logo: appstoreImg,
//     link: "https://apps.apple.com/in/app/bonatra/id1600079061",
//   },
//   {
//     logo: ambitionboxImg,
//     link: "https://www.ambitionbox.com/reviews/bonatra-reviews",
//   },
// ];
